import React, { useReducer, useEffect } from 'react';

import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import PropTypes from 'prop-types';

import reducer from '../../store/reducers/widget';
import * as actions from '../../store/actions/widgetAC';

import UserSpells from './UserSpells/UserSpells';
import SpellBook from './SpellBook/SpellBook';
import SpellBookMenuTrigger from './SpellBook/SpellBookMenuTrigger/SpellBookMenuTrigger';

import Loader from '../Loader/Loader';
import Popup from '../Popup/Popup';

import commonStyles from '../CommonStyles.css';
import styles from './RugApps.css';

const initialState = {
  hoveredSpellId: 0,
  applications: [],
  userDefinedSpells: [],
  appsLoading: false,
  spellsLoading: false,
  saveSpellsLoading: false
};

const rugApps = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    actions.fetchApplicationsConfig(dispatch, props.spellbarConfigEndpointUrl, props.accessToken);
    actions.fetchUserDefinedSpells(dispatch, props.spellbarConfigEndpointUrl, props.accessToken);
  }, []);

  // hotkey nav management
  document.onkeydown = (evt) => {
    evt = evt || window.event;

    const activeElement = document.activeElement;
    const inputs = ['input', 'textarea'];

    if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
      return;
    }

    let keyPressed = -1;

    if (evt.keyCode === 49 || evt.keyCode === 97) { // 1
      keyPressed = 1;
    }
    if (evt.keyCode === 50 || evt.keyCode === 98) { // 2
      keyPressed = 2;
    }
    if (evt.keyCode === 51 || evt.keyCode === 99) { // 3
      keyPressed = 3;
    }
    if (evt.keyCode === 52 || evt.keyCode === 100) { // 4
      keyPressed = 4;
    }
    if (evt.keyCode === 53 || evt.keyCode === 101) { // 5
      keyPressed = 5;
    }

    if (keyPressed > 0) {
      const spell = state.userDefinedSpells.find(item => item.spellId === keyPressed);

      if (spell) {
        const appConfig = state.applications.find(item => item.id === spell.appId);

        if (appConfig) {
          if (typeof (props.onBeforeRedirect) === 'function' && props.onBeforeRedirect(appConfig)) {
            window.location.href = appConfig.appUrl;
          } else if (typeof (props.onBeforeRedirect) !== 'function') {
            window.location.href = appConfig.appUrl;
          }
        }
      }
    }
  }

  const handleSpellBarItemClick = (e) => {
    if (e.currentTarget) {
      e.currentTarget.focus();
    }
  }

  const handleDefineUserSpellClick = (e, appConfig, spellId) => {
    actions.updateUserDefinedSpells(dispatch, props.spellbarConfigEndpointUrl, props.accessToken, spellId, {
      appId: appConfig.id,
      spellId: spellId
    });
  }

  return (
    <div className={styles.WidgetContainer}>
      {state.appsLoading || state.spellsLoading || state.saveSpellsLoading
        ? <Loader />
        : <React.Fragment>
          {
            <UserSpells
              spellbarConfigEndpointUrl={props.spellbarConfigEndpointUrl}
              accessToken={props.accessToken}
              dispatch={dispatch}
              applications={state.applications}
              userDefinedSpells={state.userDefinedSpells}
              hoveredSpellId={state.hoveredSpellId}
              handleSpellBarItemClick={handleSpellBarItemClick}
              handleDefineUserSpellClick={handleDefineUserSpellClick}
              handleSetHoveredSpellId={(hoveredSpellId) => actions.setHoveredSpellId(dispatch, hoveredSpellId)} />
          }
          <Popup className={commonStyles.MenuPopup}
            on='click'
            trigger={<SpellBookMenuTrigger />}
            content={<SpellBook
              className={commonStyles.MenuPopupContent}
              applications={state.applications} />} />
        </React.Fragment>}
    </div>
  )
}

rugApps.propTypes = {
  accessToken: PropTypes.string.isRequired,
  onBeforeRedirect: PropTypes.func,
  spellbarConfigEndpointUrl: PropTypes.string.isRequired
};

const RugAppsWidget = DragDropContext(HTML5Backend)(rugApps);

export { RugAppsWidget };