import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';
import { loadUser } from 'redux-oidc';
import * as oidcActions from 'redux-oidc/src/actions/index';

import PropTypes from 'prop-types';

import Callback from './Callback/Callback';

import { userManager } from './shared/userManager';
import * as utils from './shared/utils';

class RpmOidc extends React.Component {

  componentWillMount() {
    if (this.props.useForbiddenRedirectHooks) {
      utils.initRedirectIntercept();
    }
  }

  componentDidMount() {
    this.validateUser();
  }

  validateUser = () => {
    let shouldRedirect = true;

    if (typeof (this.props.onBeforeOidcRedirect) === 'function') {
      shouldRedirect = this.props.onBeforeOidcRedirect();
    }

    if (shouldRedirect && window.location && window.location.pathname !== this.props.redirectPath) {
      localStorage.setItem('callbackUrl', window.location.pathname);

      userManager.getUser().then(response => {
        if (!response || response.expired) {
          oidcActions.userExpired();
          userManager.signinRedirect();
        }

        oidcActions.userFound(response);
      });

      loadUser(this.props.store, userManager);
    }
  }

  render() {
    return (
      <OidcProvider store={this.props.store} userManager={userManager}>
        <ConnectedRouter history={this.props.history}>
          <Switch>
            <Route path={this.props.redirectPath}
              component={() => <Callback
                userManager={userManager}
                callbackPageContent={this.props.callbackPageContent} />} />
            {this.props.children}
          </Switch>
        </ConnectedRouter>
      </OidcProvider>
    );
  }
}

RpmOidc.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  redirectPath: PropTypes.string.isRequired,
  callbackPageContent: PropTypes.object,
  useForbiddenRedirectHooks: PropTypes.bool,
  onBeforeOidcRedirect: PropTypes.func
};

RpmOidc.defaultProps = {
  useForbiddenRedirectHooks: true
};

export { RpmOidc };
