/* Applications Config */
export const FETCH_APPLLICATIONS_CONFIG_START = 'FETCH_APPLLICATIONS_CONFIG_START';
export const FETCH_APPLLICATIONS_CONFIG_SUCCESS = 'FETCH_APPLLICATIONS_CONFIG_SUCCESS';

/* User Defined Spells */
export const FETCH_USER_DEFINED_SPELLS_START = 'FETCH_USER_DEFINED_SPELLS_START';
export const FETCH_USER_DEFINED_SPELLS_SUCCESS = 'FETCH_USER_DEFINED_SPELLS_SUCCESS';
export const UPDATE_USER_DEFINED_SPELLS_START = 'UPDATE_USER_DEFINED_SPELLS_START';
export const UPDATE_USER_DEFINED_SPELLS_SUCCESS = 'UPDATE_USER_DEFINED_SPELLS_SUCCESS';

/* Spells */
export const SET_HOVERED_SPELL_ID = 'SET_HOVERED_SPELL_ID';