import * as actionTypes from '../actionTypes';

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLLICATIONS_CONFIG_START:
      return { ...state, appsLoading: true };
    case actionTypes.FETCH_APPLLICATIONS_CONFIG_SUCCESS:
      return { ...state, applications: action.applications, appsLoading: false };
    case actionTypes.FETCH_USER_DEFINED_SPELLS_START:
      return { ...state, spellsLoading: true };
    case actionTypes.FETCH_USER_DEFINED_SPELLS_SUCCESS:
      return { ...state, userDefinedSpells: action.userDefinedSpells, spellsLoading: false };
    case actionTypes.UPDATE_USER_DEFINED_SPELLS_START:
      return { ...state, saveSpellsLoading: true };
    case actionTypes.UPDATE_USER_DEFINED_SPELLS_SUCCESS:
      return { ...state, userDefinedSpells: action.userDefinedSpells, saveSpellsLoading: false };
    case actionTypes.SET_HOVERED_SPELL_ID:
      return { ...state, hoveredSpellId: action.hoveredSpellId };
    default:
      return state;
  }
};

export default reducer;