
import * as actionTypes from '../actionTypes';

const fetchApplicationsConfigStart = () => {
  return {
    type: actionTypes.FETCH_APPLLICATIONS_CONFIG_START
  };
}

const fetchApplicationsConfigSuccess = (applications) => {
  return {
    type: actionTypes.FETCH_APPLLICATIONS_CONFIG_SUCCESS,
    applications: applications
  };
}

export const fetchApplicationsConfig = (dispatch, spellbarConfigEndpointUrl, accessToken) => {
  dispatch(fetchApplicationsConfigStart());

  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", spellbarConfigEndpointUrl + '/get-applications', true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    xhr.onload = () => resolve(xhr);
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
  }).then(res => {
    if (res.status === 200) {
      return JSON.parse(res.responseText);
    }
    throw new Error('Network response was not ok.');
  }).then(res => {
    dispatch(fetchApplicationsConfigSuccess(res ? res : []));
  });
}

const fetchUserDefinedSpellsStart = () => {
  return {
    type: actionTypes.FETCH_USER_DEFINED_SPELLS_START
  };
}

const fetchUserDefinedSpellsSuccess = (userDefinedSpells) => {
  return {
    type: actionTypes.FETCH_USER_DEFINED_SPELLS_SUCCESS,
    userDefinedSpells: userDefinedSpells
  };
}

export const fetchUserDefinedSpells = (dispatch, spellbarConfigEndpointUrl, accessToken) => {
  dispatch(fetchUserDefinedSpellsStart());

  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", spellbarConfigEndpointUrl + '/get-spellbar-config', true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    xhr.onload = () => resolve(xhr);
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
  }).then(res => {
    if (res.status === 200) {
      return JSON.parse(res.responseText);
    }
    throw new Error('Network response was not ok.');
  }).then(res => {
    dispatch(fetchUserDefinedSpellsSuccess(res.spellConfig ? JSON.parse(res.spellConfig) : []));
  });
}

export const setHoveredSpellId = (dispatch, hoveredSpellId) => {
  dispatch({
    type: actionTypes.SET_HOVERED_SPELL_ID,
    hoveredSpellId: hoveredSpellId
  });
}

const updateUserDefinedSpellsStart = () => {
  return {
    type: actionTypes.UPDATE_USER_DEFINED_SPELLS_START
  };
}

const updateUserDefinedSpellsSuccess = (userDefinedSpells) => {
  return {
    type: actionTypes.UPDATE_USER_DEFINED_SPELLS_SUCCESS,
    userDefinedSpells: userDefinedSpells
  };
}

export const updateUserDefinedSpells = (dispatch, spellbarConfigEndpointUrl, accessToken, spellItemId, updatedSpell) => {
  dispatch(updateUserDefinedSpellsStart());

  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", spellbarConfigEndpointUrl + `/update-spellbar-config?spellItemId=${spellItemId}`, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    xhr.onload = () => resolve(xhr);
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(JSON.stringify(updatedSpell));
  }).then(res => {
    if (res.status === 200) {
      return JSON.parse(res.responseText);
    }
    throw new Error('Network response was not ok.');
  }).then(res => {
    dispatch(updateUserDefinedSpellsSuccess(res.spellConfig ? JSON.parse(res.spellConfig) : []));
  });
}